<template>
  
  
  <div
    v-if="question"
    class="content"
    :style="{backgroundImage: `url(/maps/${scenario.mapImage})`}"
  >
    <main role="main">
      <div
        class="content-overlay brand-font-bold"
        :class="{hidden: minimised}"
      >
        <div class="content-overlay__lights">
          <div class="light strobe blue">
            <div class="inner-light"/>
          </div>
          <div class="light strobe blue delay">
            <div class="inner-light"/>
          </div>
          <div class="light strobe blue">
            <div class="inner-light"/>
          </div>
          <div class="light strobe blue delay">
            <div class="inner-light"/>
          </div>
        </div>
        
        
        <div class="content-overlay__scenario">
          <div
            v-if="question.time || question.weather"
            class="content-overlay__header"
          >
            <span v-if="question.time">{{ question.time }}</span>
            <div role="img" :aria-label="question.weather" v-if="question.weather">
              <img :src="`${publicPath}icons/${question.weather}.svg`" :alt="question.weather">
            </div>
          </div>
          
          <div
            v-if="parseInt($route.params.qid) === 1"
            class="content-overlay__call-incoming"
          >
            <h1>999</h1>
            call incoming
          </div>
          
          <div
            class="content-overlay__description"
            v-html="question.scenario"
          />
        </div>
        
        <div class="content-overlay__question">
          <h2 class="content-overlay__question-label">
            Question {{ $route.params.qid }}
          </h2>
          <p class="content-overlay__question-text">
            {{ question.text }}
          </p>
          
          <div class="content-overlay__question-actions">
            <router-link :to="`/scenario/${$route.params.id}/question/${$route.params.qid}/answer`">
              <ul>
                <li>
                  <button role="button">
                    <Helicopter/>
                  </button>
                </li>
                <li v-if="parseInt($route.params.qid) === 1">
                  <button role="button">
                    <Ambulance/>
                  </button>
                </li>
                <li v-else>
                  <button role="button">
                    <LargeAmbulance/>
                  </button>
                </li>
              </ul>
            </router-link>
          </div>
        </div>
        
        
        <button
          class="content-overlay__toggle"
          :class="{active: minimised}"
          @click="toggleView"
          role="button"
        >
          <ArrowLeft/>
          {{ toggleText }}
        </button>
      </div>
    </main>
    
    <Incident
      v-if="question.showIncident"
      class="incident-icon"
      :style="{top: scenario.incidentLocation.top, left: scenario.incidentLocation.left}"
    />
    <Hospital
      v-for="(location, index) in scenario.hospitalLocations"
      :key="index"
      class="hospital-icon"
      :style="{top: location.top, left: location.left}"
    />
    <Base
      v-if="question.baseLocation"
      class="base-icon"
      :style="{top: question.baseLocation.top, left: question.baseLocation.left}"
    />
    
    <nav class="page-navigation" role="navigation">
      <div>
        <router-link
          v-if="backLink"
          :to="backLink"
        >
          <div>
            <ArrowLeft/>
            <div>Back</div>
          </div>
        </router-link>
      </div>
      
      <div>
        <router-link :to="`/scenario/${$route.params.id}/question/${$route.params.qid}/answer`">
          <div>
            <ArrowRight/>
            <div>Next</div>
          </div>
        </router-link>
      </div>
    </nav>
    <div class="text-toggle" @click="increaseFontSize">
      <IncreaseFontSize/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ArrowRight from '@/components/icons/ArrowRight.vue'
import ArrowLeft from '@/components/icons/ArrowLeft.vue'
import Ambulance from '@/components/icons/Ambulance.vue'
import LargeAmbulance from '@/components/icons/LargeAmbulance.vue'
import Helicopter from '@/components/icons/Helicopter.vue'
import Hospital from '@/components/icons/Hospital.vue'
import Incident from '@/components/icons/Incident.vue'
import Base from '@/components/icons/Base.vue'
import IncreaseFontSize from '@/components/icons/IncreaseFontSize.vue'

export default {
  name: 'Scenario',
  components: {
    ArrowRight,
    ArrowLeft,
    Ambulance,
    LargeAmbulance,
    Helicopter,
    Incident,
    Hospital,
    Base,
    IncreaseFontSize
  },
  data() {
    // Configure the defaults for the data
    // Scenario: the current scenario
    // Question: the current scenario question
    // Minimised: if the text content is hidden or not
    // ToggleText: the default text to show on the toggle button
    // PublicPath: The base URL for accessing the web root media
    return {
      scenario: null,
      question: null,
      minimised: false,
      toggleText: 'Hide to view full map',
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    backLink() {
      // No back link unless the question ID is greater than 1
      return this.$route.params.qid > 1 ? `/scenario/${this.$route.params.id}/question/${this.$route.params.qid - 1}` : null
    }
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      {immediate: true}
    )
  },
  methods: {
    increaseFontSize() {
      if (document.body.classList.contains('increased-font-size')) {
        // Do something
        document.body.classList.remove('increased-font-size')
      } else {
        document.body.classList.add('increased-font-size')
      }
    },
    toggleView() {
      // Toggle the text content to be hidden
      
      // Inverse the property
      this.minimised = !this.minimised;
      
      // Change the toggle text
      this.toggleText = this.minimised ? 'Show' : 'Hide to view full map';
    },
    async fetchData() {
      // Set the question and scenario to null until it's populated
      this.question = null
      this.scenario = null
      
      // Import the scenarion JSON file
      await import('@/content/scenarios/scenario-' + this.$route.params.id + '.json').then((content) => {
        // Get the questions
        const questions = content.default.questions
        
        // Get the question index we're looking for
        const questionIndex = parseInt(this.$route.params.qid) - 1
        
        // Check if the question for this index exists, if not redirect to home
        if (typeof questions[questionIndex] === 'undefined') {
          this.$router.push('/')
        } else {
          // Assign the scenario and question
          this.scenario = content.default
          this.question = questions[questionIndex]
          
          // Set the last played scenario in storage
          localStorage.setItem('last-scenario', this.$route.params.id);
        }
      }).catch(() => {
        // If there's any issue opening the JSON file then redirect to home
        this.$router.push('/')
      })
    }
  }
}
</script>

<style scoped>
.content {
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-toggle {
  position: absolute;
  bottom: 100px;
  right: 100px;
  background: #FEC000;
  border-radius: 50%;
  padding: 58px;
  cursor: pointer;
}

.text-toggle svg {
  width: 180px;
}

.incident-icon, .hospital-icon, .base-icon {
  position: absolute;
  width: 238px;
  height: auto;
}

.content-overlay {
  font-size: 70px;
  width: 50%;
  z-index: 999;
  position: relative;
  top: 3em;
  left: 0;
  transition: .35s ease all;
}

.content-overlay.hidden {
  left: -50%;
}

.content-overlay.hidden .content-overlay__lights {
  opacity: 0;
}

.content-overlay__question {
  background-color: #304251;
  padding: 1.1em;
}

.content-overlay__question-text {
  font-size: 81px;
  margin-bottom: .75em;
  margin-top: .5em;
}

.content-overlay__scenario {
  background-color: #E1001E;
  padding: 1.1em;
  border-radius: 0 1.2em 0 0;
}

.content-overlay__header {
  font-size: 105px;
  border-bottom: 4px solid white;
  padding-bottom: .5em;
  margin-bottom: .5em;
  display: flex;
}

.content-overlay__header span {
  flex: 1 1 50%;
}

.content-overlay__header div img {
  height: 100px;
}

.content-overlay__header div:last-of-type {
  text-align: right;
}

.content-overlay__call-incoming {
  font-size: 105px;
  line-height: 1;
  text-align: center;
  border-bottom: 4px solid white;
  padding-bottom: .5em;
  margin-bottom: .5em;
}

.content-overlay__call-incoming div {
  font-size: 339px;
  margin-bottom: -15px;
  margin-top: -40px;
}

.content-overlay__lights {
  display: flex;
  height: 100%;
  position: absolute;
  flex-wrap: wrap;
  width: 100%;
  z-index: -1;
}

.content-overlay__question-label {
  font-size: 70px;
  margin: 0;
}

.light {
  position: relative;
  flex: 0 0 100%;
  animation-duration: 800ms;
  animation-name: light;
  animation-iteration-count: infinite;
  display: inline-block;
}

.light:first-of-type .inner-light {
  border-radius: 0 1.2em 0 0;
}

.light:last-of-type .inner-light {
  border-radius: 0 0 1.2em 0;
}

.inner-light {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: -1px;
  left: -1px;
  border-radius: 21px;
  border: 1px solid transparent;
  opacity: 0;
}

.delay .inner-light {
  animation-delay: 400ms;
}

.strobe.blue .inner-light {
  animation-duration: 800ms;
  animation-name: strobe-blue;
  animation-iteration-count: infinite;
}

.strobe.red .inner-light {
  animation-duration: 800ms;
  animation-name: strobe-red;
  animation-iteration-count: infinite;
}

@keyframes strobe-red {
  0%, 25% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  28%, 50% {
    border: 1px solid #ee2819;
    box-shadow: 0px 0px 100px 25px #ff4444;
    opacity: 1;
  }
  52%, 55% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  57%, 69% {
    border: 1px solid #ee2819;
    box-shadow: 0px 0px 100px 25px #ff4444;
    opacity: 1;
  }
  70%, 71% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  72%, 75% {
    border: 1px solid #ee2819;
    box-shadow: 0px 0px 100px 25px #ff4444;
    opacity: 1;
  }
  77%, 100% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
}

@keyframes strobe-blue {
  0%, 25% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  28%, 50% {
    border: 1px solid #139eff;
    box-shadow: 0px 0px 100px 25px #0078ff;
    opacity: 1;
  }
  52%, 55% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  57%, 69% {
    border: 1px solid #139eff;
    box-shadow: 0px 0px 100px 25px #0078ff;
    opacity: 1;
  }
  70%, 71% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
  72%, 75% {
    border: 1px solid #139eff;
    box-shadow: 0px 0px 100px 25px #0078ff;
    opacity: 1;
  }
  77%, 100% {
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid #111;
    opacity: 0;
  }
}

h1 {
  margin-bottom: 0;
}

.content-overlay__question-actions ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: .75em;
  align-items: center;
  justify-content: flex-start;
}

.content-overlay__question-actions ul li {
  display: inline-block;
  height: 200px;
  width: 200px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
}

.content-overlay__question-actions ul li button {
  border: 0;
  background: unset;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.content-overlay__question-actions ul li button svg {
  width: 91%;
  height: auto;
}

.content-overlay__question-actions {
  margin-top: .75em;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: .75em;
}

.page-navigation {
  position: absolute;
  z-index: 777;
  top: 70%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.page-navigation > div {
  overflow: hidden;
}

.page-navigation > div:first-of-type a {
  right: auto;
  left: -50%;
  text-align: right;
}

.page-navigation > div:first-of-type a svg {
  right: 15px;
  left: auto;
}

.page-navigation a {
  background: #FEC000;
  height: 350px;
  width: 350px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  right: -50%;
  text-decoration: none;
}

.page-navigation a > div {
  font-size: 50px;
  color: #E1001E;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  left: .75em;
  text-transform: uppercase;
}

.page-navigation > div:first-of-type a > div {
  right: .75em;
  left: auto;
}

.page-navigation a > div > div {
  margin-top: .25em;
}

.page-navigation a svg {
  width: 1.75em;
  height: auto;
  position: relative;
  left: .25em;
}

.content-overlay__toggle {
  left: 0;
  display: flex;
  gap: .5em;
  width: 100%;
  background: #FEC000;
  color: #E1001E;
  padding: .5em 1.1em;
  border-radius: 0 0 1.2em 0;
  text-align: center;
  z-index: 0;
  box-sizing: border-box;
  transition: .35s ease all;
  transition-delay: 0s;
  font-size: 70px;
  font-family: MahsuriSansMTW01,Helvetica,Arial,sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
}

.content-overlay__toggle svg {
  transition: .35s ease all;
  transform: rotate(0deg);
  width: 55px;
  height: auto;
  font-size: 70px;
  line-height: 70px;
  margin: auto 0;
}

.content-overlay__toggle.active {
  position: absolute;
  left: 100%;
  width: auto;
  display: block;
  padding: 30px;
  border-radius: 0 60px 60px 0;
  transition-delay: .35s;
}

.content-overlay__toggle.active svg {
  transform: rotate(180deg);
}
</style>
